<template>
  <div>
    <div ref="editorContainer"
      class="quill-editor" />
  </div>
</template>

<script>
export default {
  name: 'QuillEditor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    value(val) {
      if (this.editor && this.editor.root.innerHTML !== val) {
        this.editor.root.innerHTML = val
      }
    }
  },
  mounted() {
    this.initializeEditor()
  },
  beforeDestroy() {
    this.removeDropdownEventListeners()
  },
  methods: {
    addDropdownEventListeners() {
      const toolbar = this.editor.getModule('toolbar')
      const dropdowns = toolbar.container.querySelectorAll('.ql-picker')

      dropdowns.forEach((dropdown) => {
        dropdown.addEventListener('mousedown', this.handleDropdownClick)
      })
    },
    initializeEditor() {
      const Size = Quill.import('attributors/style/size')
      Size.whitelist = null // Allow all sizes
      Quill.register(Size, true)

      const Font = Quill.import('attributors/style/font')
      Font.whitelist = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Narrow', 'Garamond',
        'Georgia', 'Impact', 'Sans Serif', 'Serif', 'Tahoma', 'Trebuchet MS', 'Verdana']
      Quill.register(Font, true)

      const toolbarSizes = ['4px', '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px',
        '24px', '28px', '32px', '48px', '58px', '68px', '78px']
      const toolbarOptions = [
        [{ 'size': toolbarSizes }],
        [{ 'font': Font.whitelist }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        ['link', 'image', 'video'],
        ['clean']
      ]

      this.editor = new Quill(this.$refs.editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        }
      })

      const sanitisedHTML = DOMPurify.sanitize(this.value)
      const parsedHTML = this.processHTML(sanitisedHTML)
      this.editor.clipboard.dangerouslyPasteHTML(parsedHTML)
      this.editor.on('text-change', () => {
        this.$emit('input', this.editor.root.innerHTML)
      })
      this.addDropdownEventListeners()
    },

    handleDropdownClick(event) {
      event.preventDefault()
      setTimeout(() => {
        this.editor.focus()
      }, 0)
    },
    processHTML(html) {
      const convertedHtml = html.replace(/<font\s+([^>]*)>/g, (match, content) => {
        let styles = ''
        const faceMatch = content.match(/face="([^"]*)"/)
        const colorMatch = content.match(/color="([^"]*)"/)
        const fontWeightMatch = content.match(/font-weight:\s*([^;]*)/)
        const sizeMatch = content.match(/size="([^"]*)(px)?"/)
        if (faceMatch) { styles += `font-family: ${faceMatch[1]};` }
        if (colorMatch) { styles += `color: ${colorMatch[1]};` }
        if (fontWeightMatch) { styles += `font-weight: ${fontWeightMatch[1]};`}
        if (sizeMatch) {
          const sizeValue = sizeMatch[1]
          const unit = sizeMatch[2] ? sizeMatch[2] : 'em'
          styles += `font-size: ${sizeValue}${unit};`
        }
        return `<span style="${styles}">`
      }).replace(/<\/font>/g, '</span>')
      return convertedHtml
    },
    removeDropdownEventListeners() {
      const toolbar = this.editor.getModule('toolbar')
      const dropdowns = toolbar.container.querySelectorAll('.ql-picker')

      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener('mousedown', this.handleDropdownClick)
      })
    }
  }
}
</script>

<style lang="scss">
.ql-snow .ql-font.ql-picker {
  width: 140px;
}

.ql-snow .ql-size.ql-picker {
  width: 80px;
}

$fonts: (
  "Arial",
  "Arial Black",
  "Comic Sans MS",
  "Courier New",
  'Narrow',
  'Garamond',
  'Georgia',
  'Impact',
  'Sans Serif',
  'Serif',
  'Tahoma',
  'Trebuchet MS',
  'Verdana'
);

@each $font in $fonts {
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="#{$font}"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="#{$font}"]::before {
    content: "#{$font}";
  }
}

$sizes: (
  '4px', '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px',
    '24px', '28px', '32px', '48px', '58px', '68px', '78px'
);

@each $size in $sizes {
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="#{$size}"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="#{$size}"]::before {
    content: "#{$size}";
  }
}
</style>